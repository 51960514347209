<template>
  <!-- eslint-disable  -->
  <div>
    <v-card flat class="w100p">
      <!--  -->
    </v-card>
    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{ $store.getters['app/getRouteTitle'] }}
      <v-spacer></v-spacer>
    </div>

    <FilterBox
      :open.sync="isFilterOpen"
      v-model="filter"
      :loading="isLoading"
      :options="filterOptions"
      @search="fetchData"
    />

    <v-card
      outlined
      :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
    >
      <v-card-text class="d-flex align-center pb-0 pb-sm-4">
        <v-dialog v-model="isInfoOpen" v-if="$vuetify.breakpoint.smAndDown">
          <v-card>
            <v-card-text class="py-3">
              總訂單數 : {{ result.total }}
              <br />
              成功單數 : {{ statistics.paid_count || 0 }}
              <br />
              成功總金額 : {{ statistics.paid_amt || 0 }} 元
            </v-card-text>
          </v-card>
        </v-dialog>
        <div v-else class="d-flex align-center flex-wrap mb-4 mb-sm-0">
          總訂單數 : {{ result.total }}
          <v-divider vertical class="mx-3"></v-divider>
          成功單數 : {{ statistics.paid_count || 0 }}
          <v-divider vertical class="mx-3"></v-divider>
          成功總金額 : {{ statistics.paid_amt || 0 }} 元
        </div>
        <v-spacer></v-spacer>
        <RefreshBtn :fetchData="fetchData" :loading="isLoading" />

        <SizeBox width="14" />
        <ExportBtn
          :items="result.list"
          :headers="headers"
          :file-name="`${
            $store.getters['app/getRouteTitle']
          } ${$moment().format('YYYY-MM-DD_H:mm:ss')}`"
          :labels="labels"
          sheet-name="sheetName"
          :exclude="['actions']"
        />
      </v-card-text>

      <DataTable
        :options.sync="options"
        :loading="isLoading"
        :list="result.list"
        :fields="headers"
        :total="result.total"
        :labels="labels"
        :replace="[
          'actions',
          'user',
          'platform_order_no',
          'created_at',
          'bank_card_no',
          'amt',
          'bank_holder',
          'bank_name',
          'bank_branch',
          'notified_at|formatTime',
          'note'
        ]"
        @get-list="fetchData"
      >
        <template #item.actions="{ item }">
          <div class="flex-center">
            <v-menu
              v-if="
                item.actions &&
                item.actions.some &&
                item.actions.some((key) => !key.disabled(item))
              "
            >
              <template #activator="{ on }">
                <v-btn
                  v-on="on"
                  color="Secondary100"
                  rounded
                  :small="$vuetify.breakpoint.smAndDown"
                >
                  操作
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>

              <v-list rounded>
                <v-list-item
                  v-for="(key, i) in item.actions"
                  v-show="!key.disabled(item)"
                  :key="`key:${item.id} ${i}`"
                  @click="
                    key.action && handleAction((vm) => key.action(vm, item))
                  "
                  :disabled="key.grant ? !$getGrantValue(key.grant) : false"
                >
                  <v-icon v-if="!!key.icon" size="18" class="mr-1">
                    {{ key.icon }}
                  </v-icon>
                  {{ key.label }}
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn v-else disabled color="Secondary100" depressed rounded>
              無法操作
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </div>
        </template>
        <template #item.user="{ item }">
          {{ item.user && item.user.name }}
        </template>
        <template #item.platform_order_no="{ item }">
          <div>
            <div class="text-no-wrap">{{ item.platform_order_no || '-' }}</div>
            <div class="text-no-wrap">{{ item.merchant_order_no || '-' }}</div>
          </div>
        </template>
        <template #item.amt="{ item }">
          <span class="Error500--text">
            {{ item.amt }}
          </span>
        </template>
        <template #item.bank_holder="{ item }">
          <span class="Error500--text">
            {{ item.bank_holder }}
          </span>
        </template>
        <template #item.bank_card_no="{ item }">
          <div :class="{ 'cursor-not-allowed': !isCanShow(item) }">
            <v-btn
              rounded
              color="Secondary100"
              small
              depressed
              :disabled="!isCanShow(item)"
              @click="openForm('BankInfo', item)"
            >
              <span class="Error500--text">
                {{ item.bank_card_no }}
              </span>
            </v-btn>
          </div>
        </template>
        <template #item.bank_name="{ item }">
          <span class="Error500--text">
            {{ item.bank_name }}
          </span>
        </template>
        <template #item.bank_branch="{ item }">
          <span>
            {{ item.bank_branch }}
          </span>
        </template>
        <template #item.created_at="{ item }">
          <div>
            <div class="text-no-wrap">{{ item.created_at | formatTime }}</div>
            <div v-if="item.succeeded_at" class="text-no-wrap">
              {{ item.succeeded_at | formatTime }}
            </div>
            <div v-else class="text-no-wrap error--text">未完成</div>
          </div>
        </template>
        <template #item.note="{ item }">
          <div class="note-wrap">{{ item.note }}</div>
        </template>
      </DataTable>
    </v-card>
    <PayInfoForm
      :value="showingForm === 'BankInfo'"
      @close="showingForm = null"
      :passData="passData"
    />
    <OrderSetForm
      :value="showingForm === 'OrderSet'"
      @close="showingForm = null"
      @save="fetchData"
      :passData="passData"
    />
    <OrderEditForm
      :value="showingForm === 'OrderEdit'"
      @close="showingForm = null"
      @save="fetchData"
      :passData="passData"
    />
    <FunctionFab
      @info="isInfoOpen = true"
      @refresh="fetchData()"
      @open-filter="isFilterOpen = true"
      :no-filter="!filterOptions.length"
      :no-info="false"
    />
  </div>
</template>

<script>
import {
  getMerchantPayOrderList,
  pickUpMerchantPayOrder,
  releaseMerchantPayOrder
} from '@/api/payOrders';
import PayInfoForm from '@/components/pay/PayInfoForm.vue';
import OrderSetForm from '@/components/pay/OrderSetForm.vue';
import OrderEditForm from '@/components/pay/OrderEditForm.vue';

import { getCollectChannels } from '@/api/collectChannels';

export default {
  components: {
    PayInfoForm,
    OrderSetForm,
    OrderEditForm
  },
  data() {
    return {
      ...this.$clone(this.$commonSchema),

      statistics: {
        paid_count: 0,
        paid_amt: 0
      },
      isInfoOpen: false,
      tempStorage: {
        /**
         * [id]: {
         *  bank_holder
         *  bank_card_no
         *  bank_name
         *  bank_branch
         * }
         */
      },
      importantInfos: [
        'bank_holder',
        'bank_card_no',
        'bank_name',
        'bank_branch'
      ],
      headers: [
        { text: '操作', value: 'actions', align: 'center' },
        {
          text: '撿單人員',
          value: 'user',
          align: 'center',
          dataFormat: (v) => (v && v.name) || ''
        },
        {
          text: '平台單號\n/\n商戶單號',
          value: 'platform_order_no',
          align: 'center',
          width: '360px',
          join: 'merchant_order_no'
        },
        { text: '商戶 ID', value: 'merchant.serial', align: 'center' },
        { text: '代付狀態', value: 'status', align: 'center' },
        {
          text: '申請金額',
          sortable: true,
          width: '100px',
          value: 'amt',
          align: 'center'
        },
        {
          text: '申請者 IP',
          value: 'client_ip',
          align: 'center',
          width: '120px'
        },
        this.$getGrantValue('pay_order_list.view_fee') && {
          text: '手續費',
          value: 'fee',
          align: 'center'
        },
        {
          text: '到帳金額',
          sortable: true,
          width: '100px',
          value: 'actual_amt',
          align: 'center'
        },
        {
          text: '代付通道',
          width: '100px',
          value: 'pay_channel.name',
          align: 'center'
        },
        {
          text: '收款姓名',
          value: 'bank_holder',
          align: 'center'
        },
        {
          text: '銀行卡號',
          value: 'bank_card_no',
          align: 'center'
        },
        {
          text: '銀行名稱',
          value: 'bank_name',
          align: 'center'
        },
        {
          text: '分行名稱',
          value: 'bank_branch',
          align: 'center'
        },
        {
          text: '建立時間\n/\n成功時間',
          value: 'created_at',
          dataFormat: this.$root.$options.filters.formatTime,
          align: 'center',
          join: 'succeeded_at'
        },
        {
          text: '通知狀態',
          value: 'notification_status',
          align: 'center',
          join: 'succeeded_at'
        },
        {
          text: '通知時間',
          value: 'notified_at',
          dataFormat: this.$root.$options.filters.formatTime,
          align: 'center',
          join: 'succeeded_at'
        },
        { text: '備註', value: 'note', align: 'center' }
      ].filter((v) => !!v),
      labels: {
        status: [
          { value: 'pending', text: '未處理', color: 'Error200' },
          { value: 'in_progress', text: '處理中', color: 'Alert200' },
          { value: 'rejected', text: '已駁回', color: 'Error400' },
          { value: 'paid', text: '已付款', color: 'Primary200' },
          { value: 'completed', text: '已完成', color: 'Success300' }
        ],
        notification_status: [
          { text: '未發送', value: 'unsent', color: 'Error200' },
          { text: '發送成功', value: 'success', color: 'Success300' },
          { text: '發送失敗', value: 'failure', color: 'Error400' }
        ]
      },
      filterOptions: [
        {
          label: '平台單號',
          type: 'text',
          name: 'filter.platform_order_no'
        },
        {
          label: '商戶單號',
          type: 'text',
          name: 'filter.merchant_order_no'
        },
        {
          label: '商戶 ID',
          type: 'text',
          name: 'filter.merchant_serial'
        },
        {
          label: '支付通道',
          type: 'autocomplete',
          name: 'filter.paid_collect_channel_id',
          api: getCollectChannels
        },
        {
          label: '收款姓名',
          type: 'text',
          name: 'filter.bank_holder'
        },
        {
          label: '代付狀態',
          type: 'select',
          name: 'filter.status',
          items: [
            { text: '未處理', value: 'pending' },
            { text: '處理中', value: 'in_progress' },
            { text: '已駁回', value: 'rejected' },
            { text: '已付款', value: 'paid' },
            { text: '已完成', value: 'completed' }
          ]
        },
        {
          label: '建立時間',
          type: 'timeSet',
          name: 'filter.created_at',
          range: true,
          withTime: true
        },
        {
          label: '成功時間',
          type: 'timeSet',
          name: 'filter.succeeded_at',
          range: true,
          withTime: true
        },
        {
          label: '通知狀態',
          type: 'select',
          name: 'filter.notification_status',
          items: [
            { text: '未發送', value: 'unsent' },
            { text: '發送成功', value: 'success' },
            { text: '發送失敗', value: 'failure' }
          ]
        },
        {
          label: '通知時間',
          type: 'timeSet',
          name: 'filter.notified_at',
          range: true,
          withTime: true
        }
      ],

      actions: [
        {
          label: '撿單',
          icon: 'mdi-circle-outline',
          disabled: (v) => {
            return v.user
              ? true
              : !this.$getGrantValue('pay_order_list.assign') ||
                  v.status !== 'pending';
          },
          action: (vm, v) => {
            this.$confirm({
              title: '撿單',
              content: '確認是否撿單',
              btn: {
                color: 'primary',
                text: '撿單'
              },
              callback: async () => {
                const { success } = await pickUpMerchantPayOrder({
                  id: v.id
                });
                if (success) {
                  this.$toast('撿單成功');
                  this.fetchData();
                  !this.$getGrantValue('pay_order_list.show') &&
                    this.setImportantInfos(v);
                } else {
                  this.$toast.error('撿單失敗');
                }
              }
            });
          }
        },
        {
          label: '編輯',
          icon: 'mdi-pencil',
          disabled: (v) =>
            this.isCantEdit(v)
              ? true
              : !this.$getGrantValue('pay_order_list.edit'),
          action: (vm, v) => {
            this.openForm('OrderEdit', v);
          }
        },
        {
          label: '設定',
          icon: 'mdi-cog-outline',
          disabled: (v) =>
            this.isCantSet(v)
              ? true
              : !this.$getGrantValue('pay_order_list.setting'),
          action: (vm, v) => {
            this.openForm('OrderSet', v);
          }
        },
        {
          label: '釋出',
          icon: 'mdi-export',
          disabled: (v) =>
            this.isCantRelease(v)
              ? true
              : !this.$getGrantValue('pay_order_list.release'),
          action: (vm, v) => {
            this.$confirm({
              title: '釋出',
              content: '確認是否釋出',
              btn: {
                color: 'primary',
                text: '釋出'
              },
              callback: async () => {
                const { success } = await releaseMerchantPayOrder({
                  id: v.id
                });
                if (success) {
                  this.$toast('釋出成功');
                  this.fetchData();
                  !this.$getGrantValue('pay_order_list.show') &&
                    this.hideImportantInfos(v);
                } else {
                  this.$toast.error('釋出失敗');
                }
              }
            });
          }
        }
      ]
    };
  },

  watch: {
    options: {
      deep: true,
      handler(v) {
        const payload = {
          ...this.condition,
          sorts: [{ column: v.sortBy[0], sort: v.sortDesc[0] ? 'desc' : 'asc' }]
        };
        if (!v.sortBy.length) delete payload.sorts;
        this.fetchData(payload);
      }
    }
  },

  async created() {
    this.fetchData();
  },

  methods: {
    async fetchData(condition = this.condition) {
      this.isLoading = true;
      this.condition = condition;
      const Order = await getMerchantPayOrderList({
        ...this.filter,
        ...condition
      });

      if (!Order.error) {
        this.result.total = Order.total;
        this.result.page = Order.page;

        this.statistics = Order.statistics;
        this.$set(
          this.result,
          'list',
          Order.items.map((item, idx) => {
            const newItem = {
              ...item,
              idx,
              actions: [...this.actions]
            };
            const isCanShow = this.isCanShow(item);
            if (!isCanShow) {
              this.importantInfos.forEach((key) => {
                this.$set(this.tempStorage, item.id, {
                  ...this.tempStorage[item.id],
                  [key]: item[key]
                });
                newItem[key] = '*****';
              });
            }
            return newItem;
          })
        );
      }

      this.isLoading = false;
    },
    openForm(name, data = {}, mode = '') {
      this.showingForm = name;

      this.$set(this, 'passData', {
        mode,
        ...data
      });
    },
    handleAction(fn) {
      fn(this);
    },
    setImportantInfos(item) {
      Object.keys(this.tempStorage[item.id]).forEach((key) => {
        this.$set(
          this.result.list[item.idx],
          key,
          this.tempStorage[item.id][key]
        );
      });
    },
    hideImportantInfos(item) {
      this.importantInfos.forEach((key) => {
        this.$set(this.tempStorage, item.id, {
          ...this.tempStorage[item.id],
          [key]: item[key]
        });
        this.$set(this.result.list[item.idx], key, '*****');
      });
    },
    isCanShow(v) {
      return this.$getGrantValue('pay_order_list.show')
        ? true
        : !this.isCantSet(v);
    },
    isCantEdit(v) {
      const currentUser = this.$store.getters['auth/getUser'];
      const orderUser = v.user || {};
      const isComplete = v.status === 'paid' || v.status === 'completed';
      if (!orderUser.id) return true;
      if (!isComplete) return true;
      return !(currentUser.id === orderUser.id);
    },
    isCantSet(v) {
      const currentUser = this.$store.getters['auth/getUser'];
      const orderUser = v.user || {};
      const releasable = v.status === 'pending' || v.status === 'in_progress';
      if (!orderUser.id) return true;
      if (!releasable) return true;
      return !(currentUser.id === orderUser.id);
    },
    isCantRelease(v) {
      const currentUser = this.$store.getters['auth/getUser'];
      const orderUser = v.user || {};
      const releasable = v.status === 'pending' || v.status === 'in_progress';
      if (!orderUser.id) return true;
      if (!releasable) return true;
      return !(currentUser.id === orderUser.id);
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
